import { gsap } from "gsap"
import { useLayoutEffect, useRef } from "react";
import AsyncImage from "./_common/AsyncImage";

export default function HeadingSection() {
    const container = useRef();
    const heading = useRef();

    useLayoutEffect(() => {
        let ctx = gsap.context(() => {
            gsap.from(heading.current, {
                y: 0,
                opacity: 1,
                duration: 2, 
                scrollTrigger: {
                    scrub: true,
                },
            })
        }, container);
        return () => ctx.revert();
    }, []);
    
    return (     
      <header ref={container} className="App-header min-h-[1000px] relative flex flex-col items-center justify-center sm:overflow-hidden" id="home">
        <AsyncImage src="./SM_earth4.jpg" alt="Black marketing image header image of the earth from space." className="absolute top-0 left-0 w-full object-cover h-full block lg:hidden" />
        <AsyncImage src="./LG_earth4.png" alt="Black marketing image header image of the earth from space." className="absolute top-0 left-0 w-full object-cover hidden lg:block" />

        <div className='px-8 sm:px-4'>
          <h1 className='text-3xl sm:text-4xl lg:text-5xl font-Blackout leading-none'>
            <p ref={heading} className={`translate-y-[200px] md:translate-y-[600px]`}><span className="whitespace-nowrap">Grow your</span><br/>
            brand</p>
          </h1>
        </div>
      </header>
    )
}